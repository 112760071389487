import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import '@clout-team/web-components/build/assets/styles/style.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {replaceDefaultConsoleLog} from "./utils/functions";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
    dsn: "https://54e54c363ca84bc5b68a6878498b79bd@o1248645.ingest.sentry.io/6408727",
    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,
});

// Patch native addEventListener *before* ReactDOM.render call.
const addEventListener = Node.prototype.addEventListener;
Node.prototype.addEventListener = function(type, listener, options) {
    if (type === "wheel") {
        // Disable because of https://github.com/facebook/react/issues/20662
        // Note: this would disable any onWheel listeners.
        // You could also limit this to particular roots, e.g.
        // if (type === 'wheel' && this.id === 'root') { return }
        return;
    }
    // @ts-ignore
    return addEventListener.apply(this, arguments);
};


// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('space-root'))
root.render(
    <React.StrictMode><App/></React.StrictMode>
);

replaceDefaultConsoleLog();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
