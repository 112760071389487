import './wdyr';
import React, {useEffect, useState} from 'react';
import './assets/colors.scss';
import '@clout-team/web-components/build/index.css';
import MainPage from "./MainPage";
import {checkAuth} from "./utils/functions";


function App() {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(()=>{
        checkAuth().then(res=>{
           if(res?.status === 200) setIsAuth(true);
        });
    }, [])

    return (<>{isAuth && <MainPage/> || <></>}</>);
}

export default App;
