import React, {useState} from 'react';
import {ApplicationStorage, Spaces, WebHeader} from '@clout-team/web-components';
import dayjs from "dayjs";

localStorage.setItem('releaseDate', process.env.REACT_APP_RELEASE_DATE ?
    dayjs(process.env.REACT_APP_RELEASE_DATE).format("DD.MM.YYYY") + "" : "null");


const MainPage = () => {
    const [search, setSearch] = useState("")
    return (<ApplicationStorage>
        <WebHeader
            search={search}
            onSearch={() => {}}
            changeSearch={(val: any) => setSearch(val)}
            module={"Spaces"}
        />
        <Spaces left={true}/>
    </ApplicationStorage>);
};

export default MainPage;
