import {Cookies} from "react-cookie";
import axios from "axios";
import {BASE_URL, SPACE_MAP} from "./mock";

export const replaceDefaultConsoleLog = function () {
    //TODO check if need
    let consoleHolder = console;

    if (!(process.env.NODE_ENV === 'development')) {
        consoleHolder = window.console;
        // @ts-ignore
        window.console = {};
        Object.keys(consoleHolder).forEach(function (key) {
            // @ts-ignore
            window.console[key] = function () {
            };
        })
    } else {
        window.console = consoleHolder;
    }
};

export const checkAuth = async () => {
    const cookie = new Cookies();
    const token = cookie?.get("token");
    if (token) {
        const res = await axios.get(`${BASE_URL}${SPACE_MAP.GET_PROFILE}?&token=${token}`).catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) reDirectToAuth();
            }
        });
        return res;
    } else reDirectToAuth();
}

const reDirectToAuth = () => {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    switch (subdomain) {
        case "dev-space":
          location.href = "https://dev-auth.lenzaos.com";
            break;
        case "stable-space":
          location.href = "https://stable-auth.lenzaos.com";
            break;
        default :
          location.href = "https://auth.lenzaos.com";
            break;
    }
}
